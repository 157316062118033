import React from "react"
import HeaderPaginas from "../../../components/headerPaginas"
import Layout from "../../../components/layout"
import Seo from "../../../components/SEO/SEO"
import { GrTrigger, GrShield, GrYoga, GrSchedule } from "react-icons/gr"
import { IconContext } from "react-icons"
import Boton from "../../../components/boton"
import "../../../styles/tarjetas_servicios.scss"
import { Link } from "gatsby"

export default function servidorDedicado(props) {
  return (
    <Layout>
      <Seo pathname={props.location.pathname} />
      <div className="cabecera-servicio">
        <HeaderPaginas
          titulo="Copias de seguridad: Backups"
          imagen="/img/cabeceras/backup_bullhost.jpg"
        />
        <div className="intro-servicio">
          <p>
            Las empresas con información vital y/o gran volumen de datos
            necesitan un paso más en sus sistemas de backup.
          </p>
        </div>
      </div>
      <div className="cuerpo-servicio">
        <div className="fila-cards-cpd-propio-caracteristicas limitador">
          <div className="card-cpd-propio-caracteristicas">
            <div
              className="col-imagen"
              style={{ backgroundImage: "url(/img/backup-cloud-bullhost.jpg)" }}
            >
              {/* <StaticImage src="../../../static/img/bullhost-cloud-robusto-cpd.jpg" alt="Cloud robusto" /> */}
            </div>
            <div className="col-texto">
              <div>
                <p>
                  No hay un sistema de copias de seguridad infalible.
                  Generalmente son varios sistemas los que garantizan la
                  protección de tu información: las copias deben trabajar en
                  equipo, como nosotros. Una copia en local bien gestionada te
                  dará toda la rapidez necesaria en una recuperación, pero no
                  sólo podrás confiar en tu infraestructura.
                </p>
                <p>
                  Una copia en la nube te da seguridad y confianza, estamos
                  nosotros protegiéndola. Y un plan de recuperación ante
                  desastres (Disaster Recovery Plan) es la excelencia, porque
                  con él se garantiza siempre la recuperación o continuación de
                  los sistemas tecnológicos de vital importancia en tu
                  organización.
                </p>
              </div>
              <div>
                <h3>Nuestro backup en la nube</h3>
                <p>
                  Es un proceso automatizado responsable de crear una copia de
                  seguridad online (en el caso de Bullhost, en nuestro{" "}
                  <Link to="/cloud-robusto/">Cloud Robusto</Link>). El servicio
                  de copias de seguridad en la nube siempre está automatizado lo
                  que supone una despreocupación total por parte de nuestros
                  clientes. Queda en manos de nuestra infraestructura y de
                  nuestro equipo y con unos niveles de garantía de servicio del
                  99,9%.
                </p>
                <p>
                  No pienses que tú no necesitas un sistema de copias de
                  seguridad. Un sistema de backup es necesario para cualquier
                  empresa o proyecto, pero mucho más si es crítico. Bullhost lo
                  ofrece en modo multiplataforma (Unix, Linux, Windows y MacOS).
                  Y todos nuestros backup se alojan en nuestro data center local
                  alojado en España: sabes dónde está tu nube siempre.
                  Localizada. Y protegida.
                </p>
              </div>
            </div>
          </div>
        </div>
        <section className="bloque-tarjetas limitador">
          <h3>Algunas de nuestras características</h3>
          <div className="fila-cards-cpd-propio">
            <IconContext.Provider
              value={{
                fill: "#1f1f1f",
                stroke: "#1f1f1f",
                color: "#1f1f1f",
                size: "30%",
                className: "cpd-icono-svg",
              }}
            >
              <div className="card-cpd-propio">
                <div className="card-cpd-propio__icono">
                  <GrYoga />
                </div>
                <div className="card-cpd-propio__info">
                  <h3>Flexible</h3>
                  <p>
                    Es posible realizar la copia remota de cualquier tipo de
                    fichero o base de datos.
                  </p>
                </div>
              </div>
              <div className="card-cpd-propio">
                <div className="card-cpd-propio__icono">
                  <GrSchedule />
                </div>
                <div className="card-cpd-propio__info">
                  <h3>Automatizado</h3>
                  <p>
                    Se programa la periodicidad y el sistema actúa de forma
                    independiente realizando el backup online.
                  </p>
                </div>
              </div>
              <div className="card-cpd-propio">
                <div className="card-cpd-propio__icono">
                  <GrShield />
                </div>
                <div className="card-cpd-propio__info">
                  <h3>Seguro</h3>
                  <p>
                    Desde tu sede hasta nuestros servidores la información
                    siempre viaja encriptada y securizada.
                  </p>
                </div>
              </div>
              <div className="card-cpd-propio">
                <div className="card-cpd-propio__icono">
                  <GrTrigger />
                </div>
                <div className="card-cpd-propio__info">
                  <h3>Eficiente</h3>
                  <p>
                    El consumo del ancho de banda es mínimo por el sistema de
                    compresión de los datos a enviar.
                  </p>
                </div>
              </div>
            </IconContext.Provider>
          </div>
        </section>
      </div>
      {/* CTA para contacto */}
      <section className="bloque-oscuro contacto-background">
        <div className="overlay-contacto"></div>
        <div className="header-inicio limitador">
          <div className="detalle destacado">
            <p>Contacto</p>
          </div>
          <div className="titulo">
            <h2>
              Nuestro equipo se encarga del diseño, montaje y mantenimiento de
              todas nuestras máquinas.
            </h2>
            <p>¿Quieres un presupuesto personalizado?</p>
            <Boton
              color="secundario"
              url="/contacto/"
              texto="Contactar"
              externo={false}
            />
          </div>
        </div>
      </section>
    </Layout>
  )
}
